// @flow
import React from 'react'
import StarRatings from 'react-star-ratings'

import * as v from '../../config/variables'

type Props = {
  id: number | string,
  size?: number,
  spacing?: number,
  rating: number,
  onChange: Function,
}

const StarRating = (props: Props) => {
  const { id, size, spacing, rating, onChange = () => {} } = props

  return (
    <StarRatings
      id={id}
      rating={rating || 0}
      changeRating={(value) => {
        onChange(id, value)
      }}
      numberOfStars={5}
      starRatedColor={v.brandColor}
      starHoverColor={v.brandColor}
      starDimension={size || '2rem'}
      starSpacing={spacing || '0.3rem'}
      {...props}
    />
    // @flow
  )
}

export default StarRating
