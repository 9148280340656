// @flow
import React from 'react'
import { navigate, graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import { Container } from 'reactstrap'

import mapAirtableData from '../lib/mapAirtableData'

import { ButtonLink } from '../components/UI'
import Layout, { SEO } from '../components/Layout'

const VerfassenSuccessScreen = () => {
  const data = useStaticQuery(graphql`
    query {
      allAirtable(filter: { table: { eq: "Verfassen" } }) {
        edges {
          node {
            recordId
            data {
              Name
              Text
            }
          }
        }
      }
    }
  `)
  const { allAirtable } = data
  const texts = mapAirtableData(allAirtable)

  return (
    <Layout>
      <Container>
        <SEO title="Rezensionen" />

        <h1>{texts['Überschrift Erfolg']}</h1>

        <ReactMarkdown source={texts['Text Erfolg']} />

        <ButtonLink onClick={() => navigate('/app')}>
          Zurück zur Startseite
        </ButtonLink>
      </Container>
    </Layout>
  )
}

export default VerfassenSuccessScreen
