// @flow
import React from 'react'

import styled from 'styled-components'
import ReviewListItem from './ReviewListItem'

type Props = {
  reviews: Array<Object>,
}

const StyledReviewList = styled.ul`
  list-style-type: none;
  margin: 1rem 0;
  padding: 0;
`

const ReviewList = (props: Props) => {
  const { reviews } = props

  if (!reviews.length) return null

  return (
    <StyledReviewList>
      {reviews.map((review) => {
        // console.log(review)
        const { id: itemId } = review
        return <ReviewListItem key={itemId} review={review} />
      })}
    </StyledReviewList>
  )
}

export default ReviewList
