// @flow
import React, { useState, useEffect } from 'react'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ActionCreators from '../../store/actions'

import { LoadingIndicator } from '../UI'
import ReviewList from './ReviewList'
import ListFilter from './ListFilter'
import Pagination from './Pagination'

type Props = {
  reviews: Object,
  loadReviews: Function,
}

const ReviewOverview = (props: Props) => {
  const {
    loadReviews,
    reviews: { entries, pagination },
  } = props
  const [loading, setLoading] = useState(false)
  const [loadingFinished, setLoadingFinished] = useState(false)
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState(null)
  const [sort, setSort] = useState('newest')
  const [institution, setInstitution] = useState(null)
  const hasEntries = entries && entries.length > 0

  const searchReviews = () => {
    setLoading(true)
    const sortBy = sort === 'newest' || sort === 'oldest' ? 'date' : 'title'
    const sortDir = sort === 'newest' || sort === 'zToA' ? 'desc' : 'asc'
    let currentPage = page
    if (page < 1) {
      currentPage = 1
      setPage(1)
    }

    return loadReviews(currentPage, query, sortBy, sortDir, institution)
      .then(() => {
        setLoading(false)
        setLoadingFinished(true)
      })
      .catch(() => {
        setLoading(false)
        setLoadingFinished(true)
      })
  }

  // useEffect(() => {
  //   setLoading(true)
  //   searchReviews()
  // }, [])
  useEffect(() => {
    setPage(-999)
  }, [sort, institution])

  useEffect(() => {
    searchReviews()
  }, [page])

  return (
    <>
      <ListFilter
        onChange={({
          query: currentQuery,
          sort: currentSort,
          institution: currentInstitution,
        }) => {
          setQuery(currentQuery)
          setSort(currentSort)
          setInstitution(currentInstitution)
        }}
        onSubmit={searchReviews}
        loading={loading}
      />

      <LoadingIndicator loading={loading} />

      {!loading && hasEntries && <ReviewList reviews={entries} />}

      {!loading && !hasEntries && loadingFinished && (
        <p>
          <strong>
            Sorry, für deine Suche wurden keine Ergebnisse gefunden.
          </strong>
        </p>
      )}

      {hasEntries && (
        <Pagination
          pagination={pagination}
          currentPage={page}
          onChange={({ selected: currentPage }) => {
            setPage(currentPage + 1)
          }}
        />
      )}
    </>
  )
}

function mapStateToProps({ reviews }) {
  return { reviews }
}

function mapDispatchToProps(dispatch: Function) {
  return {
    dispatch,
    ...bindActionCreators(ActionCreators, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewOverview)
