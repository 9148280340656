// @flow
import styled from 'styled-components'
import SelectSearch from 'react-select-search'
import { lighten } from 'polished'

import * as v from '../../config/variables'

const inputHeight = '47px'

const StyledSelectionInput = styled(SelectSearch)`
  width: 100%;
  position: relative;
  /* margin-bottom: 20px; */
  background: #fff;
  border-radius: ${v.borderRadiusDefault};
  box-shadow: 0 0 0 1px ${v.grayLight};
  transition: box-shadow ease 0.2s;

  &:focus {
    box-shadow: 0 0 0 3px ${lighten(0.2, v.brandColor)};
  }

  &--multiple {
    border-radius: ${v.borderRadiusDefault};
    /* box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.07); */
    overflow: hidden;
  }

  &--focus {
    box-shadow: 0 0 0 3px ${lighten(0.2, v.brandColor)};
  }

  &::after {
    font-family: fontawesome;
    content: '\f078';
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    text-align: center;
    line-height: ${inputHeight};
    color: #222f3e;
    z-index: 1;
  }

  &--multiple::after,
  &--focus::after {
    content: '\f002';
  }

  &__icon--disabled::after {
    content: none !important;
  }

  &--input::after {
    display: none !important;
  }

  &__out {
    display: none;
  }

  &__search {
    display: block;
    width: 100%;
    height: ${inputHeight};
    border: none;
    background: none;
    outline: none;
    font-size: 1rem;
    color: #222f3e;
    -webkit-appearance: none;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    cursor: pointer;
    /* font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; */
    line-height: ${inputHeight};
    /* box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.07); */
    box-shadow: 0 0 0 1px ${v.grayLight};

    padding: 0.5rem 0.8rem;
    border-radius: ${v.borderRadiusDefault};
    background-color: ${v.grayLighter};
    transition: box-shadow ease 0.2s;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px ${lighten(0.2, v.brandColor)};
    }
  }

  input&__search {
    line-height: 1;
  }

  &--multiple &__search {
    box-shadow: none;
  }

  &--input &__search {
    cursor: text;
  }

  &__search:focus {
    cursor: text;
  }

  &__search--placeholder {
    font-weight: normal;
    color: ${v.textColor};
  }

  & input::-webkit-input-placeholder {
    font-weight: normal;
    color: ${v.textColor};
  }

  & input::-moz-placeholder {
    font-weight: normal;
    color: ${v.textColor};
  }

  & input:-moz-placeholder {
    font-weight: normal;
    color: ${v.textColor};
  }

  & input:-ms-input-placeholder {
    font-weight: normal;
    color: ${v.textColor};
  }

  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  &__select {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background: #fff;
    border-radius: ${v.borderRadiusDefault};
    overflow: auto;
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.07);
    z-index: 100;
    min-height: 49px;
  }

  &--multiple &__select {
    display: block;
    position: static;
    border-top: 1px solid #eee;
    border-radius: 0;
    box-shadow: none;
  }

  &__select--display {
    display: block;
  }

  ul&__options {
    padding: 0;
    margin: 0;
  }

  &__option {
    font-size: 0.9rem;
    font-weight: 400;
    color: #616b74;
    padding: 0.5rem 0.7rem;
    margin: 0;
    border-top: 1px solid #eee;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
  }

  &__option:first-child {
    border-top: none;
  }

  &__option--hover,
  &__option:hover {
    background: ${lighten(0.6, v.brandColor)};
  }

  &__option--selected {
    background: ${v.brandColor};
    color: #fff;
    border-top-color: ${v.brandColor};
  }

  &__option--selected:hover,
  &__option--selected&__option--hover {
    background: ${v.brandColor};
    color: #fff;
    /* border-top-color: #2184ff; */
  }

  &__group {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #eee;
    position: relative;
  }

  &__group-header {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 0 10px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: pre;
  }
`

export default StyledSelectionInput
