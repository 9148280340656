// @flow
const methodsData = [
  { name: 'Quellenrecherche, Textarbeit', value: '1' },
  { name: 'Archive', value: '2' },
  { name: 'Interviews/Gespräche', value: '3' },
  { name: 'Apparative Diagnostik (EKG, EEG, …)', value: '4' },
  {
    name: 'Grundlagen im Labor (wetlab, ELISA, …)',
    value: '5',
  },
  { name: 'Zellkultur', value: '6' },
  {
    name: 'Lebendes Gewebe (Slices, Organoide)',
    value: '7',
  },
  { name: 'Histologie (Immunhisto, ImageJ, …)', value: '8' },
  { name: 'Mikroskopie (Lasermikroskopie, EM, …)', value: '9' },
  { name: 'Cell Sorting (FACS, …)', value: '10' },
  { name: 'DNA-Analysen (Sequencing, Plasmide, …)', value: '11' },
  {
    name: 'Mathematische Modelle, Big Data (MatLab, …)',
    value: '12',
  },
  { name: 'Statistik (Microsoft Excel, R, SPSS, …)', value: '13' },
  { name: 'Sonstige (unten ausführen)', value: '14' },
]

export default methodsData
