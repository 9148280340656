// @flow
import React from 'react'
import { navigate, graphql, useStaticQuery } from 'gatsby'

// Redux
import { connect } from 'react-redux'

import { Container } from 'reactstrap'

import mapAirtableData from '../lib/mapAirtableData'

import { ButtonLink } from '../components/UI'
import Layout, { SEO } from '../components/Layout'
import ReviewDetail from './components/ReviewDetail'

type Props = {
  recordId: string,
  currentReview: Object,
}

const RezensionenDetailScreen = (props: Props) => {
  const data = useStaticQuery(graphql`
    query {
      allAirtable(filter: { table: { eq: "Rezension Detail" } }) {
        edges {
          node {
            recordId
            data {
              Name
              Text
            }
          }
        }
      }
    }
  `)
  const { allAirtable } = data
  const texts = mapAirtableData(allAirtable)
  const {
    recordId,
    currentReview: { isLoaded, title },
  } = props

  return (
    <Layout>
      <Container>
        <SEO title={isLoaded ? title : texts['Überschrift 1']} />

        <ButtonLink onClick={() => navigate('/app/rezensionen')}>
          ← Zurück
        </ButtonLink>

        <ReviewDetail recordId={recordId} />
      </Container>
    </Layout>
  )
}

function mapStateToProps({ currentReview }) {
  return { currentReview }
}

export default connect(mapStateToProps)(RezensionenDetailScreen)
