// @flow
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { Container } from 'reactstrap'

import mapAirtableData from '../lib/mapAirtableData'

// import { Button } from '../components/UI'
import Layout, { SEO } from '../components/Layout'
import NewReviewForm from '../components/NewReviewForm'

const VerfassenScreen = () => {
  const data = useStaticQuery(graphql`
    query {
      allAirtable(filter: { table: { eq: "Verfassen" } }) {
        edges {
          node {
            recordId
            data {
              Name
              Text
            }
          }
        }
      }
    }
  `)
  const { allAirtable } = data
  const texts = mapAirtableData(allAirtable)

  return (
    <Layout>
      <Container>
        <SEO title="Rezension verfassen" />

        <h1>{texts['Überschrift 1']}</h1>
        <ReactMarkdown source={texts['Text Einleitung']} />

        <NewReviewForm texts={texts} />
      </Container>
    </Layout>
  )
}

export default VerfassenScreen
