// @flow
import styled from 'styled-components'

export const Block = styled.div`
  margin: 0rem 0 3rem;
`

export const BlockTitle = styled.h2`
  /* font-size: 1.3rem; */
  margin-top: 0;
  margin-bottom: 0.5rem;
`

export const BlockContent = styled.p`
  & > p:last-child {
    margin-bottom: 0;
  }
`
