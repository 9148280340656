// @flow
import React from 'react'
import { navigate } from 'gatsby'
import { Formik, Form } from 'formik'
import styled from 'styled-components'
import moment from 'moment'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col } from 'reactstrap'
import ActionCreators from '../../store/actions'

import { DisplayFormikState } from './helper'
import {
  FieldSet,
  TextInput,
  TextArea,
  SelectionInput,
  StarRatingInput,
  // DatePickerInput,
  ValidationBox,
} from '../Form'
import { Button } from '../UI'
import validationSchema from './validationSchema'
import categoryData from './categoryData'
import doctoralThesisType from './doctoralThesisTypeData'
import methodsData from './methodsData'

const StyledNewReviewForm = styled.div``

const SubmitButton = styled(Button)``

const initialValues = {
  profTitle: '',
  profFirstName: '',
  profLastName: '',
  supervisorTitle: '',
  supervisorFirstName: '',
  supervisorLastName: '',
  institution: '',
  otherInstitution: '',
  beginOfSupervisorship: '',
  endOfSupervisorship: '',
  doctoralThesisType: '',
  methods: '',
  positiveAspects: '',
  negativeAspects: '',
  ratingOverall: 0,
  ratingCompetence: 0,
  ratingStructure: 0,
  ratingConsultations: 0,
  ratingCommitment: 0,
  ratingPromotion: 0,
}

const dummyData = () => ({
  profTitle: 'Prof.',
  profFirstName: 'Lara',
  profLastName: 'Lustig',
  supervisorTitle: 'Prof. Dr.',
  supervisorFirstName: 'Peter',
  supervisorLastName: 'Harper',
  institution: '323',
  otherInstitution: '',
  beginOfSupervisorship: moment('31.03.2017', 'DD.MM.YYYY')
    .startOf('quarter')
    .toISOString(),
  endOfSupervisorship: moment('30.06.2019', 'DD.MM.YYYY')
    .startOf('quarter')
    .toISOString(),
  doctoralThesisType: '',
  methods: '',
  positiveAspects: 'Lorem ipsum…',
  negativeAspects: 'Lorem ipsum 2… ',
  ratingOverall: 4,
  ratingCompetence: 3,
  ratingStructure: 5,
  ratingConsultations: 2,
  ratingCommitment: 5,
  ratingPromotion: 4,
})

const INSTITUTION_NOT_LISTED = '393'

const quarterData = Array(10)
  .fill(null)
  .map((y, i) => {
    const currentYear = moment().subtract(i, 'years').year()
    const quarters = [1, 2, 3, 4].map((q) => {
      const quarter = moment(`${q} ${currentYear}`, 'Q Y')
      return {
        name: quarter.format('Q[. Quartal] Y'),
        value: quarter.toISOString(),
      }
    })

    return {
      type: 'group',
      name: currentYear,
      items: quarters,
    }
  })

type Props = {
  createReview: Function,
  texts: Object,
}

const NewReviewForm = (props: Props) => {
  const { createReview, texts } = props

  const _handleSubmit = (values, setSubmitting) => {
    // alert(JSON.stringify(values, null, 2))

    createReview(values)
      .then(() => {
        setSubmitting(false)
        navigate('/app/verfassen/erfolg')
      })
      .catch(() => {
        setSubmitting(false)
        navigate('/app/error')
        // handle fail
      })
  }

  return (
    <StyledNewReviewForm>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) =>
          _handleSubmit(values, setSubmitting)
        }
      >
        {(formProps) => {
          const {
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            setValues,
            errors,
            touched,
            isSubmitting,
            isValid,
            submitCount,
            setFieldTouched,
          } = formProps

          return (
            <Form onSubmit={handleSubmit}>
              <FieldSet>
                {process.env.NODE_ENV === 'development' && (
                  <>
                    <Button
                      type="button"
                      onClick={() => setValues(dummyData())}
                    >
                      Fill dummy data
                    </Button>
                    &nbsp;
                    <Button
                      type="button"
                      onClick={() => setValues(initialValues)}
                    >
                      Clear data
                    </Button>
                  </>
                )}

                <h2>Dein/e Doktorvater/Doktormutter</h2>

                <Row>
                  <Col sm={{ size: 3 }}>
                    <TextInput
                      id="profTitle"
                      type="text"
                      label="Titel"
                      placeholder="Titel"
                      error={touched.profTitle && errors.profTitle}
                      value={values.profTitle}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <TextInput
                      id="profFirstName"
                      type="text"
                      label="Vorname *"
                      placeholder="Vorname"
                      error={touched.profFirstName && errors.profFirstName}
                      value={values.profFirstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col>
                    <TextInput
                      id="profLastName"
                      type="text"
                      label="Nachname *"
                      placeholder="Nachname"
                      error={touched.profLastName && errors.profLastName}
                      value={values.profLastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
              </FieldSet>

              <FieldSet>
                <h2>Dein/e Betreuer/in</h2>

                <Row>
                  <Col sm={{ size: 3 }}>
                    <TextInput
                      id="supervisorTitle"
                      type="text"
                      label="Titel"
                      placeholder="Titel"
                      error={touched.supervisorTitle && errors.supervisorTitle}
                      value={values.supervisorTitle}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={{ size: 6 }}>
                    <TextInput
                      id="supervisorFirstName"
                      type="text"
                      label="Vorname *"
                      placeholder="Vorname"
                      error={
                        touched.supervisorFirstName &&
                        errors.supervisorFirstName
                      }
                      value={values.supervisorFirstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col md={{ size: 6 }}>
                    <TextInput
                      id="supervisorLastName"
                      type="text"
                      label="Nachname *"
                      placeholder="Nachname"
                      error={
                        touched.supervisorLastName && errors.supervisorLastName
                      }
                      value={values.supervisorLastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
              </FieldSet>

              <FieldSet>
                <h2>Deine Promotion</h2>
                <Row>
                  <Col md={{ size: 6 }}>
                    <SelectionInput
                      id="institution"
                      // type="text"
                      label="Fakultät/Institution *"
                      placeholder="Fakultät/Institution"
                      error={touched.institution && errors.institution}
                      value={values.institution}
                      onChange={setFieldValue}
                      onTouched={setFieldTouched}
                      onBlur={handleBlur}
                      maxLength={1000}
                      options={categoryData}
                    />
                  </Col>

                  {values.institution === INSTITUTION_NOT_LISTED && (
                    <Col md={{ size: 6 }}>
                      <TextInput
                        id="otherInstitution"
                        type="text"
                        label="Andere Institution"
                        placeholder="Andere Institution"
                        error={
                          touched.otherInstitution && errors.otherInstitution
                        }
                        value={values.otherInstitution}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Col>
                  )}
                </Row>

                <Row>
                  <Col md={{ size: 6 }}>
                    <SelectionInput
                      id="beginOfSupervisorship"
                      // type="text"
                      label="Beginn des Betreuungsverhältnisses *"
                      placeholder="Beginn des Betreuungsverhältnisse"
                      error={
                        touched.beginOfSupervisorship &&
                        errors.beginOfSupervisorship
                      }
                      value={values.beginOfSupervisorship}
                      onChange={setFieldValue}
                      onTouched={setFieldTouched}
                      onBlur={handleBlur}
                      maxLength={1000}
                      options={quarterData}
                    />
                  </Col>

                  <Col md={{ size: 6 }}>
                    <SelectionInput
                      id="endOfSupervisorship"
                      // type="text"
                      label="Ende des Betreuungsverhältnisses *"
                      placeholder="Ende des Betreuungsverhältnisse"
                      error={
                        touched.endOfSupervisorship &&
                        errors.endOfSupervisorship
                      }
                      value={values.endOfSupervisorship}
                      onChange={setFieldValue}
                      onTouched={setFieldTouched}
                      onBlur={handleBlur}
                      maxLength={1000}
                      options={quarterData}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={{ size: 6 }}>
                    <SelectionInput
                      id="doctoralThesisType"
                      // type="text"
                      label="Art der Doktorarbeit *"
                      placeholder="Art der Doktorarbeit"
                      error={
                        touched.doctoralThesisType && errors.doctoralThesisType
                      }
                      value={values.doctoralThesisType}
                      onChange={setFieldValue}
                      onTouched={setFieldTouched}
                      onBlur={handleBlur}
                      maxLength={1000}
                      options={doctoralThesisType}
                    />
                  </Col>

                  <Col md={{ size: 6 }}>
                    <SelectionInput
                      id="methods"
                      // type="text"
                      label="Genutzte Methoden *"
                      placeholder="Genutzte Methoden"
                      error={touched.methods && errors.methods}
                      value={values.methods}
                      onChange={setFieldValue}
                      onTouched={setFieldTouched}
                      onBlur={handleBlur}
                      options={methodsData}
                      multiple
                    />
                  </Col>
                </Row>

                <h3>Bewertungen</h3>

                <StarRatingInput
                  id="ratingOverall"
                  type="text"
                  label="Gesamtbewertung *"
                  placeholder="Gesamtbewertung"
                  error={touched.ratingOverall && errors.ratingOverall}
                  value={values.ratingOverall}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                />

                <Row>
                  <Col md={{ size: 4 }}>
                    <StarRatingInput
                      id="ratingCompetence"
                      type="text"
                      label="Fachkompetenz *"
                      placeholder="Fachkompetenz"
                      error={
                        touched.ratingCompetence && errors.ratingCompetence
                      }
                      value={values.ratingCompetence}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      size="1.5rem"
                      spacing="0.2rem"
                    />
                  </Col>
                  <Col md={{ size: 4 }}>
                    <StarRatingInput
                      id="ratingStructure"
                      type="text"
                      label="Struktur *"
                      placeholder="Struktur"
                      error={touched.ratingStructure && errors.ratingStructure}
                      value={values.ratingStructure}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      size="1.5rem"
                      spacing="0.2rem"
                    />
                  </Col>
                  <Col md={{ size: 4 }}>
                    <StarRatingInput
                      id="ratingConsultations"
                      type="text"
                      label="Rücksprachen *"
                      placeholder="Rücksprachen"
                      error={
                        touched.ratingConsultations &&
                        errors.ratingConsultations
                      }
                      value={values.ratingConsultations}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      size="1.5rem"
                      spacing="0.2rem"
                    />
                  </Col>
                  <Col md={{ size: 4 }}>
                    <StarRatingInput
                      id="ratingCommitment"
                      type="text"
                      label="Ehrlichkeit / Verbindlichkeit *"
                      placeholder="Ehrlichkeit / Verbindlichkeit"
                      error={
                        touched.ratingCommitment && errors.ratingCommitment
                      }
                      value={values.ratingCommitment}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      size="1.5rem"
                      spacing="0.2rem"
                    />
                  </Col>
                  <Col md={{ size: 4 }}>
                    <StarRatingInput
                      id="ratingPromotion"
                      type="text"
                      label="Förderung im weiteren Sinne *"
                      placeholder="Förderung im weiteren Sinne"
                      error={touched.ratingPromotion && errors.ratingPromotion}
                      value={values.ratingPromotion}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      size="1.5rem"
                      spacing="0.2rem"
                    />
                  </Col>
                </Row>

                <TextArea
                  id="positiveAspects"
                  label="Positive Aspekte *"
                  info="Berichte, über was Du promoviert hast. Was für ein Modell bzw. welche Methoden hast Du genutzt? Wie gestaltete sich Dein Doktorandenalltag? Was lief gut und was hast Du gelernt? Wie gestaltete sich Deine Betreuung, das Verfassen und Verteidigen der Promotion? Welche wissenschaftliche Erfahrung konntest Du sammeln? Würdest Du Deinen Betreuer/Dein Institut weiterempfehlen? (maximal 2000 Zeichen)"
                  placeholder="Schreibe etwas…"
                  error={touched.positiveAspects && errors.positiveAspects}
                  value={values.positiveAspects}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <TextArea
                  id="negativeAspects"
                  label="Negative Aspekte *"
                  info="Beschreibe, was Dir schwer fiel oder Dich frustrierte. Erzähle von Herausforderungen bei der Umsetzung Deiner Forschung oder Schwierigkeiten bei der Betreuung. Was lief bei promovierenden Kollegen*innen besser? Was hättest Du Dir im Nachhinein gewünscht? Möchtest Du etwas zukünftigen Promovierenden mitgeben? (maximal 2000 Zeichen)"
                  placeholder="Schreibe etwas…"
                  error={touched.negativeAspects && errors.negativeAspects}
                  value={values.negativeAspects}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FieldSet>

              <p>
                <small>* Pflichtfeld</small>
              </p>

              <p>
                <small>{texts.Datenverarbeitungshinweis}</small>
              </p>

              <SubmitButton type="submit" disabled={isSubmitting}>
                Absenden
              </SubmitButton>

              {!isValid && submitCount > 0 && (
                <ValidationBox error="Bitte überprüfe deine Angaben." />
              )}

              {process.env.NODE_ENV === 'development' && (
                <DisplayFormikState {...formProps} />
              )}
            </Form>
          )
        }}
      </Formik>
    </StyledNewReviewForm>
  )
}

function mapDispatchToProps(dispatch: Function) {
  return {
    dispatch,
    ...bindActionCreators(ActionCreators, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(NewReviewForm)
