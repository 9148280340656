// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import DatePicker from 'react-datepicker'
import de from 'date-fns/locale/de'
import 'react-datepicker/dist/react-datepicker.css'

import * as v from '../../config/variables'
import FormGroup from './FormGroup'
import Label from './Label'
import ValidationBox from './ValidationBox'

type Props = Object

type State = {
  startDate: Date,
}

const StyledDatePicker = styled.div`
  .react-datepicker-wrapper {
    display: block;

    .react-datepicker__input-container {
      display: block;
    }
  }
`

const StyledInput = styled.input`
  display: block;
  width: 100%;
  border: none;
  padding: 0.5rem 0.8rem;
  border-radius: 3px;
  background-color: ${v.grayLighter};
  transition: box-shadow ease 0.2s;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${lighten(0.2, v.brandColor)};
  }
`

const Input = (props: Object) => {
  const { id, type, value, onChange, placeholder } = props

  return (
    <StyledInput
      id={id}
      type={type}
      value={value}
      onChange={onChange}
      {...props}
      placeholder={placeholder}
    />
  )
}

class DatePickerInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const { value } = props
    this.state = {
      startDate: value,
    }
  }

  handleChange(date: Date) {
    const { id, onChange } = this.props

    this.setState({
      startDate: date,
    })
    onChange(id, date.getTime() / 1000)
  }

  render() {
    const { id, error, label, placeholder, value, ...props } = this.props
    const { startDate } = this.state

    return (
      <FormGroup>
        <Label htmlFor={id} error={error}>
          {label}
        </Label>

        <StyledDatePicker>
          <DatePicker
            // id={id}
            // value={value}
            selected={startDate || value ? new Date(value * 1000) : ''}
            customInput={<Input {...props} />}
            placeholderText={placeholder}
            dateFormat="Q. 'Quartal' yyyy"
            showYearDropdown
            maxDate={new Date()}
            // scrollableYearDropdown
            onChange={(date) => this.handleChange(date)}
            locale={de}
          />
        </StyledDatePicker>

        <ValidationBox error={error} />
      </FormGroup>
    )
  }
}

export default DatePickerInput
