// @flow
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'

import categoryData from '../NewReviewForm/categoryData'

import * as v from '../../config/variables'
import { Button as ButtonGeneric } from '../UI'
import StyledSelectionInput from '../Form/StyledSelectionInput'

type Props = {
  loading: boolean,
  onChange: Function,
  onSubmit: Function,
}

const FilterBox = styled.form`
  display: flex;
  flex-direction: column;

  @media (min-width: 576px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Group = styled.div`
  display: flex;

  margin: 0.5rem 0;

  @media (min-width: 576px) {
    margin: 0;
  }
`

// const SearchInput = styled.input`
//   display: block;
//   width: 100%;
//   border: none;
//   padding: 0.5rem 0.8rem;
//   border-radius: 3px;
//   background-color: ${v.grayLighter};
//   transition: box-shadow ease 0.2s;

//   &:focus {
//     outline: none;
//     box-shadow: 0 0 0 3px ${lighten(0.2, v.brandColor)};
//   }
// `

const SortInput = styled.select`
  display: block;
  font-size: 1rem;
  cursor: pointer;
  /* font-weight: 700; */
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.6em 0.8em;
  width: 100%;
  min-width: 185px;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid ${v.grayLight};
  border-radius: 3px;
  appearance: none;
  background-color: ${v.grayLighter};
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAvElEQVRIie3WPQrCMBjG8X/Fwev0NgXHgqND0dFNlA528gCOuU2u42RdFEpI89VXCjXPlifDj0B4k6KnZ46sZlEznOFFwuvhourKG7A3+4l5AkfV6PuwNE+8E0YBNkBtliZ8FUYBXkDrhFWjL8BZGK1Vo5UT/uAnIfyLPmyb1lstgDvRUXgi7kWdcCIehHrhSDwYDYID8Sg0GPbg0WgUPIInoQBFyp+r6soDsAVa23D4GSyR/3uPM5zh5cFvU0hRnnzyNKcAAAAASUVORK5CYII=');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1.2rem auto, 100%;

  &::-ms-expand {
    display: none;
  }
  &:hover {
    border-color: #888;
  }
  &:focus {
    border-color: #aaa;
    outline: none;
    box-shadow: 0 0 0 3px ${lighten(0.2, v.brandColor)};
  }
  & option {
    font-weight: normal;
  }
`

const Button = styled(ButtonGeneric)`
  /* padding: 0.3rem 1rem; */
  margin-left: 1rem;
`

const InstitutionSelection = styled(StyledSelectionInput)`
  @media (min-width: 576px) {
    /* width: 310px; */
  }

  @media (min-width: 992px) {
    width: 350px;
  }
`

const ListFilter = (props: Props) => {
  const { onChange, onSubmit, loading } = props
  const [query] = useState('')
  const [sort, setSort] = useState('newest')
  const [institution, setInstitution] = useState('')
  const [choosing, setChoosing] = useState(false)
  const institutionsData = categoryData.filter((c) => c.value !== '393')

  const handleSubmit = (e) => {
    onSubmit()
    if (e) e.preventDefault()
  }

  const resetForm = () => {
    setInstitution(null)
  }

  useEffect(() => {
    onChange({ query, sort, institution })
  })

  return (
    <FilterBox onSubmit={handleSubmit}>
      <Group>
        <InstitutionSelection
          id="institution"
          placeholder="Filtere nach Klinik/Institution…"
          value={institution}
          onChange={({ value }) => {
            // console.log(value)
            setInstitution(value)
          }}
          options={institutionsData}
          onFocus={() => setChoosing(true)}
          onBlur={() => setChoosing(false)}
        />
        {/* <SearchInput
          type="text"
          onChange={e => {
            setQuery(e.target.value)
          }}
          placeholder="Suchbegriff eingeben…"
          maxLength={50}
        /> */}

        {institution && !choosing && (
          <Button
            onClick={resetForm}
            type="submit"
            disabled={loading || !institution}
          >
            Reset
          </Button>
        )}
      </Group>

      <Group>
        <SortInput
          onChange={(e) => {
            setSort(e.target.value)
          }}
          value={sort}
        >
          <option value="newest">Neueste zuerst</option>
          <option value="oldest">Älteste zuerst</option>
          <option value="aToZ">A - Z</option>
          <option value="zToA">Z - A</option>
          {/* <option value="relevancy">Relevanz</option> */}
        </SortInput>
      </Group>
    </FilterBox>
  )
}

export default ListFilter
