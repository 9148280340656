// @flow
import * as React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import Textarea from 'react-textarea-autosize'
// import { Editor, EditorState } from 'draft-js'
// import 'draft-js/dist/Draft.css'

import * as v from '../../config/variables'
import FormGroup from './FormGroup'
import Label from './Label'
import ValidationBox from './ValidationBox'
import InfoMessage from './InfoMessage'

type Props = Object

const StyledTextarea = styled(Textarea)`
  display: block;
  width: 100%;
  border: none;
  padding: 0.5rem 0.8rem;
  border-radius: 3px;
  background-color: ${v.grayLighter};
  transition: box-shadow ease 0.2s;
  resize: none;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${lighten(0.2, v.brandColor)};
  }
`

// const StyledEditor = styled.div`
//   .DraftEditor-root {
//     border-radius: 3px;
//     background-color: ${v.grayLighter};
//     transition: box-shadow ease 0.2s;
//     padding: 0.5rem 0.8rem;

//     &:focus {
//       outline: none;
//       box-shadow: 0 0 0 3px ${lighten(0.2, v.brandColor)};
//     }
//   }
// `

const TextArea = ({
  // type,
  id,
  label,
  error,
  value,
  onChange,
  info,
  ...props
}: Props) => {
  // const [editorState, setEditorState] = React.useState(
  //   EditorState.createEmpty()
  // )

  return (
    <FormGroup>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>

      <InfoMessage>{info}</InfoMessage>

      {/* <StyledEditor>
        <Editor
          id={id}
          name={id}
          value={value}
          editorState={editorState}
          onChange={editorCurrentState => {
            setEditorState(editorCurrentState)
            onChange(id, editorCurrentState)
          }}
          // {...props}
        />
      </StyledEditor> */}

      <StyledTextarea
        id={id}
        minRows={4}
        value={value}
        onChange={onChange}
        {...props}
        // maxLength={1000}
      />

      <ValidationBox error={error} />
    </FormGroup>
  )
}

export default TextArea
