// @flow
import * as React from 'react'
// import styled from 'styled-components'
// import SelectSearch from 'react-select-search'

// import * as v from '../../config/variables'
import StyledSelectionInput from './StyledSelectionInput'
import FormGroup from './FormGroup'
import Label from './Label'
import ValidationBox from './ValidationBox'

type Props = Object

// const StyledInput = styled.input`
//   display: block;
//   width: 100%;
//   border: none;
//   padding: 0.5rem 0.8rem;
//   border-radius: 3px;
//   background-color: ${v.grayLighter};
//   transition: box-shadow ease 0.2s;

//   &:focus {
//     outline: none;
//     box-shadow: 0 0 0 3px ${lighten(0.2, v.brandColor)};
//   }
// `

// const StyledSelectionInput = styled(SelectSearch)`
//   padding: 0.5rem 0.8rem;
//   border-radius: 3px;
//   background-color: ${v.grayLighter};
// `

const SelectionInput = ({
  type,
  id,
  label,
  error,
  value,
  onChange,
  onTouched,
  onBlur,
  className,
  options,
  multiple = false,
  ...props
}: Props) => {
  return (
    <FormGroup>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>

      <StyledSelectionInput
        {...props}
        id={id}
        options={options}
        height={multiple && 150}
        value={value}
        // name="language"
        multiple={multiple}
        onChange={(selectionData, state) => {
          const formValue = multiple ? state.value : selectionData.value
          onChange(id, formValue)
        }}
        onBlur={() => {
          return onTouched ? onTouched(id, true) : null
        }}
      />

      {/* <StyledInput
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        {...props}
      /> */}

      <ValidationBox error={error} />
    </FormGroup>
  )
}

export default SelectionInput
