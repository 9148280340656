// @flow
import * as React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
// import { Input } from 'reactstrap'

import * as v from '../../config/variables'
import FormGroup from './FormGroup'
import Label from './Label'
import ValidationBox from './ValidationBox'

type Props = Object

const StyledInput = styled.input`
  display: block;
  width: 100%;
  border: none;
  padding: 0.5rem 0.8rem;
  border-radius: 3px;
  background-color: ${v.grayLighter};
  transition: box-shadow ease 0.2s;
  box-shadow: 0 0 0 1px ${v.grayLight};

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${lighten(0.2, v.brandColor)};
  }
`

const TextInput = ({
  type,
  id,
  label,
  error,
  value,
  onChange,
  className,
  ...props
}: Props) => {
  return (
    <FormGroup>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>

      <StyledInput
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        {...props}
      />

      <ValidationBox error={error} />
    </FormGroup>
  )
}

export default TextInput
