// @flow
import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Textarea from 'react-textarea-autosize'
import { lighten } from 'polished'
import { Row, Col } from 'reactstrap'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ActionCreators from '../../store/actions'

import * as v from '../../config/variables'
import { Button as ButtonGeneric } from '../../components/UI'
import mapAirtableData from '../../lib/mapAirtableData'

type Props = {
  recipientId: string,
  sendMessage: Function,
}

const StyledMessage = styled.div``

const MessageBox = styled(Textarea)`
  display: block;
  width: 100%;
  resize: none;
  border: 1px solid ${v.gray};
  border-radius: 3px;
  transition: box-shadow 0.3s ease;
  outline: none;
  padding: 0.5rem 1rem 3.6rem;
  /* margin: 0 0 1rem; */

  &:focus {
    box-shadow: 0 0 0 4px ${lighten(0.3, v.disscheckGreen)};
  }
`

const Button = styled(ButtonGeneric)`
  float: right;
  margin-top: -56px;
  margin-right: 0.5rem;
  z-index: 1;
  position: relative;
`

const Message = (props: Props) => {
  const { allAirtable } = useStaticQuery(graphql`
    query {
      allAirtable(filter: { table: { eq: "Rezension Detail" } }) {
        edges {
          node {
            recordId
            data {
              Name
              Text
            }
          }
        }
      }
    }
  `)
  const texts = mapAirtableData(allAirtable)
  const { recipientId, sendMessage } = props
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [finishedLoading, setFinishedLoading] = useState(false)
  const [error, setError] = useState(false)

  const submitMessage = () => {
    setLoading(true)
    sendMessage({
      message,
      recipientId,
    })
      .then(() => {
        setLoading(false)
        setFinishedLoading(true)
        // TODO: Handle done
      })
      .catch(() => {
        setError(true)
        setFinishedLoading(true)
        setLoading(false)
        // TODO: Handle error
      })
  }

  return (
    <StyledMessage>
      <div style={{ textAlign: 'center' }}>
        <h2 style={{ marginTop: 0 }}>{texts['Überschrift Nachrichtenbox']}</h2>

        {texts['Text Nachrichtenbox'] && <p>{texts['Text Nachrichtenbox']}</p>}
      </div>

      <Row>
        <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          {!finishedLoading && (
            <>
              <MessageBox
                minRows={4}
                placeholder="Schreibe eine Nachricht…"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value)
                }}
                maxLength={1000}
              />

              {message.length > 0 && (
                <Button
                  type="submit"
                  onClick={() => submitMessage()}
                  disabled={loading}
                >
                  {loading ? 'Wird gesendet…' : 'Senden '}
                </Button>
              )}
            </>
          )}

          {finishedLoading && !error && (
            <>
              <h2>{texts['Überschrift Nachricht Erfolg']}</h2>
              <p>{texts['Text Nachricht Erfolg']}</p>
            </>
          )}

          {finishedLoading && error && (
            <>
              <h2>{texts['Überschrift Nachricht Fehler']}</h2>
              <p>{texts['Text Nachricht Fehler']}</p>
            </>
          )}
        </Col>
      </Row>
    </StyledMessage>
  )
}

function mapDispatchToProps(dispatch: Function) {
  return {
    dispatch,
    ...bindActionCreators(ActionCreators, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(Message)
