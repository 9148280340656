// @flow
import * as React from 'react'
// import styled from 'styled-components'

// import * as v from '../../config/variables'
import StarRating from './StarRating'
import FormGroup from './FormGroup'
import Label from './Label'
import ValidationBox from './ValidationBox'

type Props = Object

const StarRatingInput = ({
  type,
  id,
  label,
  error,
  value,
  onChange,
  className,
  size,
  spacing,
  ...props
}: Props) => {
  // console.log(`Value:`, value)

  return (
    <FormGroup>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>

      <StarRating
        id={id}
        rating={value}
        onChange={onChange}
        size={size}
        spacing={spacing}
        {...props}
      />

      <ValidationBox error={error} />
    </FormGroup>
  )
}

export default StarRatingInput
