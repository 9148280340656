// @flow
const doctoralThesisType = [
  { name: 'theoretisch', value: '1' },
  { name: 'klinisch', value: '2' },
  { name: 'experimentell', value: '3' },
  { name: 'teils klinisch / teils experimentell', value: '4' },
  { name: 'Feldforschung', value: '5' },
  { name: 'Sonstige', value: '6' },
]

export default doctoralThesisType
