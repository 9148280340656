// @flow
import React from 'react'
import StarRatings from 'react-star-ratings'

import * as v from '../../config/variables'

type Props = {
  starValue: number,
  size?: string,
  spacing?: string,
}

const StarRating = ({ starValue, size, spacing }: Props) => {
  return (
    <StarRatings
      rating={starValue}
      starRatedColor={v.brandColor}
      // changeRating={this.changeRating}
      numberOfStars={5}
      name="rating"
      starDimension={size || '2rem'}
      starSpacing={spacing || '0.3rem'}
    />
  )
}

export default StarRating
