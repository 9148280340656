// @flow
import React from 'react'
import { navigate } from 'gatsby'
// eslint-disable-next-line
import { Router, Redirect } from '@reach/router'

import PrivateRoute from '../components/Routing'
import VerfassenScreen from './VerfassenScreen'
import VerfassenSuccessScreen from './VerfassenSuccessScreen'
import RezensionenScreen from './RezensionenScreen'
import RezensionenDetailScreen from './RezensionenDetailScreen'
import ErrorScreen from './ErrorScreen'
// import ListScreen from './screens/ListScreen'
// import EvolutionStageScreen from './screens/EvolutionStageScreen'

type Props = {
  location: Object,
}

const loggedIn = () => true

const NotFound = () => {
  navigate('/404')
  return null
}

const App = ({ location }: Props) => {
  const { pathname } = location

  if (!loggedIn()) return <Redirect to="/login" noThrow />

  if (pathname === '/app' || pathname === '/app/')
    return <Redirect to="/app/rezensionen" noThrow />

  return (
    <Router>
      {/* <Redirect from="/app" to="/app/overview" noThrow /> */}

      <PrivateRoute path="/app/rezensionen" component={RezensionenScreen} />
      <PrivateRoute
        path="/app/rezensionen/:recordId"
        component={RezensionenDetailScreen}
      />
      <PrivateRoute path="/app/verfassen" component={VerfassenScreen} />
      <PrivateRoute
        path="/app/verfassen/erfolg"
        component={VerfassenSuccessScreen}
      />
      <PrivateRoute path="/app/error" component={ErrorScreen} />

      <NotFound default />
    </Router>
  )
}

export default App
