// @flow
import React, { useEffect, useState } from 'react'
import TurndownService from 'turndown'
import ReactMarkdown from 'react-markdown'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import moment from 'moment'
import { Row, Col } from 'reactstrap'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ActionCreators from '../../store/actions'

import doctoralThesisTypeData from '../../components/NewReviewForm/doctoralThesisTypeData'
import methodsData from '../../components/NewReviewForm/methodsData'
import * as v from '../../config/variables'
import { Block, BlockTitle, BlockContent } from './Block'
import {
  HeadlineReview,
  LoadingIndicator,
  StatusText,
} from '../../components/UI'
import StarRating from './StarRating'
import Message from './Message'

const turndownService = new TurndownService()

type Props = {
  loadReviewDetail: Function,
  recordId: Object,
  currentReview: Object,
}

const StyledReviewDetail = styled.div`
  margin: 3rem 0 0;
`

const MetaInformation = styled.aside`
  background-color: ${v.grayLighter};
  padding: 2rem 1.5rem;
  border-radius: 3px;
  margin: 0 0 2rem;

  & > .row:last-child {
    & > [class*='col-']:last-child {
      ${Block}, ${BlockContent} {
        margin-bottom: 0;
      }
    }
  }

  & > ${Block}:last-child {
    margin-bottom: 0;

    & > ${BlockContent} {
      margin-bottom: 0;
    }
  }

  @media (min-width: 768px) {
    & > .row:last-child {
      & > [class*='col-'] {
        ${Block}, ${BlockContent} {
          margin-bottom: 0;
        }
      }
    }
  }
`

const ReviewDetail = (props: Props) => {
  const { loadReviewDetail, recordId, currentReview } = props
  const [loading, setLoading] = useState(false)

  if (!recordId) {
    navigate('/app/rezensionen', { replace: true })
    return null
  }

  useEffect(() => {
    setLoading(true)
    loadReviewDetail(recordId)
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  const { isLoaded, category, fields, author } = currentReview // description, date, title

  if (!isLoaded) return <LoadingIndicator loading={loading} />

  const { id: authorId } = author

  const {
    field_54: supervisorLastName,
    field_55: supervisorFirstName,
    field_57: supervisorTitle,
    field_58: profTitle,
    field_59: profFirstName,
    field_60: profLastName,
    field_61: startDateSupervisoryRelationship,
    field_73: endDateSupervisoryRelationship,
    // field_62: doctorandUserId, // wichtig?
    field_63: positiveAspects,
    field_64: negativeAspects,
    field_65: ratingOverall,
    field_56: ratingCompetence,
    field_66: ratingStructure,
    field_67: ratingConsultations, // ratingStructure,
    field_68: ratingCommitment,
    field_69: ratingPromotion, // Förderung im weiteren Sinne
    // field_70: attachment, // Zusätzliche Informationen
    field_74: doctoralThesisType, // Zusätzliche Informationen
    field_75: methods, // Zusätzliche Informationen
  } = fields

  const startOfSupervisorshop = moment.unix(startDateSupervisoryRelationship)
  const blurredQuarter = startOfSupervisorshop.quarter()
  const year = startOfSupervisorshop.year()
  let duration = null
  if (endDateSupervisoryRelationship) {
    const endOfSupervisorshop = moment
      .unix(endDateSupervisoryRelationship)
      .add(3, 'days') // rounding
    duration = moment.duration(endOfSupervisorshop.diff(startOfSupervisorshop))
  }

  const supervisorFullName = supervisorTitle
    ? `${supervisorTitle} ${supervisorFirstName} ${supervisorLastName}`
    : `${supervisorFirstName} ${supervisorLastName}`
  const profFullName = profTitle
    ? `${profTitle} ${profFirstName} ${profLastName}`
    : `${profFirstName} ${profLastName}`
  const { name: institutionName } = category

  const doctoralThesisTypeValue = (
    doctoralThesisTypeData.find((d) => d.value === doctoralThesisType) || {}
  ).name
  const methodsValues = methods
    ? methods
        .split(',')
        .map((m) => {
          return methodsData.find((method) => method.value === m) || {}
        })
        .sort((a, b) => a.value - b.value)
    : []

  return (
    <StyledReviewDetail>
      {/* <p style={{ margin: 0, color: v.gray }}>
        Veröffentlicht am {moment(date).format('LL')}
      </p> */}
      <HeadlineReview>
        {institutionName}: {supervisorFullName}
      </HeadlineReview>

      <MetaInformation>
        <Row>
          <Col md={{ size: 6 }}>
            <Block>
              <BlockTitle>Fakultät</BlockTitle>
              <BlockContent>{institutionName}</BlockContent>
            </Block>
          </Col>
        </Row>

        <Row>
          <Col md={{ size: 6 }}>
            <Block>
              <BlockTitle>Betreuer</BlockTitle>
              <BlockContent>{supervisorFullName}</BlockContent>
            </Block>
          </Col>
          <Col md={{ size: 6 }}>
            <Block>
              <BlockTitle>Doktorvater/Doktormutter</BlockTitle>
              <BlockContent>{profFullName}</BlockContent>
            </Block>
          </Col>
        </Row>

        <Row>
          <Col md={{ size: 6 }}>
            <Block>
              <BlockTitle>Beginn des Betreuungsverhältnisses</BlockTitle>
              <BlockContent>
                {blurredQuarter}. Quartal {year}
              </BlockContent>
            </Block>
          </Col>

          {duration && (
            <Col md={{ size: 6 }}>
              <Block>
                <BlockTitle>Betreuungszeitraum</BlockTitle>
                <BlockContent>
                  {duration.years() ? `${duration.years()} Jahre` : null}
                  {duration.months() ? ` ${duration.months()} Monate` : null}
                </BlockContent>
              </Block>
            </Col>
          )}
        </Row>

        <Row>
          <Col md={{ size: 6 }}>
            <Block>
              <BlockTitle>Art der Doktorarbeit</BlockTitle>
              <BlockContent>{doctoralThesisTypeValue}</BlockContent>
            </Block>
          </Col>

          <Col md={{ size: 6 }}>
            <Block>
              <BlockTitle>Genutzte Methoden</BlockTitle>
              <BlockContent as="div">
                {!!methodsValues.length && (
                  <ul>
                    {methodsValues.map((mv) => (
                      <li key={`method-${mv.value}`}>{mv.name}</li>
                    ))}
                  </ul>
                )}
              </BlockContent>
            </Block>
          </Col>
        </Row>
      </MetaInformation>

      <MetaInformation>
        <Row>
          <Col md={{ size: 6 }}>
            <Block>
              <BlockTitle>Gesamtbewertung</BlockTitle>

              <BlockContent as="div">
                <StarRating starValue={parseFloat(ratingOverall)} />

                <StatusText>
                  Die Gesamtbewertung wird nicht aus der Summe der anderen
                  inhaltlichen Bewertungen kalkuliert, sondern wird gesondert
                  vergeben.
                </StatusText>
              </BlockContent>
            </Block>
          </Col>
        </Row>

        <Row>
          <Col md={{ size: 4 }}>
            <Block>
              <BlockTitle as="h3">Fachkompetenz</BlockTitle>
              <BlockContent as="div">
                <StarRating
                  starValue={parseFloat(ratingCompetence)}
                  size="1.5rem"
                  spacing="0.2rem"
                />
              </BlockContent>
            </Block>
          </Col>
          <Col md={{ size: 4 }}>
            <Block>
              <BlockTitle as="h3">Struktur</BlockTitle>
              <BlockContent as="div">
                <StarRating
                  starValue={parseFloat(ratingStructure)}
                  size="1.5rem"
                  spacing="0.2rem"
                />
              </BlockContent>
            </Block>
          </Col>
          <Col md={{ size: 4 }}>
            <Block>
              <BlockTitle as="h3">Rücksprachen</BlockTitle>
              <BlockContent as="div">
                <StarRating
                  starValue={parseFloat(ratingConsultations)}
                  size="1.5rem"
                  spacing="0.2rem"
                />
              </BlockContent>
            </Block>
          </Col>
          <Col md={{ size: 4 }}>
            <Block>
              <BlockTitle as="h3">Ehrlichkeit / Verbindlichkeit</BlockTitle>
              <BlockContent as="div">
                <StarRating
                  starValue={parseFloat(ratingCommitment)}
                  size="1.5rem"
                  spacing="0.2rem"
                />
              </BlockContent>
            </Block>
          </Col>
          <Col md={{ size: 4 }}>
            <Block>
              <BlockTitle as="h3">Förderung im weiteren Sinne</BlockTitle>
              <BlockContent as="div">
                <StarRating
                  starValue={parseFloat(ratingPromotion)}
                  size="1.5rem"
                  spacing="0.2rem"
                />
              </BlockContent>
            </Block>
          </Col>
        </Row>

        <Block>
          <BlockTitle>Positive Aspekte</BlockTitle>
          <BlockContent as="div">
            <ReactMarkdown source={turndownService.turndown(positiveAspects)} />
          </BlockContent>
        </Block>

        <Block>
          <BlockTitle>Negative Aspekte</BlockTitle>
          <BlockContent as="div">
            <ReactMarkdown source={turndownService.turndown(negativeAspects)} />
          </BlockContent>
        </Block>
      </MetaInformation>

      <MetaInformation>
        <Message recipientId={authorId} />
      </MetaInformation>
      {/* <div>
        <pre
          style={{ fontSize: '0.6rem', lineHeight: '1.3', overflowX: 'auto' }}
        >
          {JSON.stringify(fields, null, 2)}
        </pre>
      </div> */}
    </StyledReviewDetail>
  )
}

function mapStateToProps({ currentReview }) {
  return { currentReview }
}

function mapDispatchToProps(dispatch: Function) {
  return {
    dispatch,
    ...bindActionCreators(ActionCreators, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewDetail)
