// @flow
import React from 'react'
import ReactPaginate from 'react-paginate'
// import styled from 'styled-components'

type Props = {
  pagination: {
    page: string,
    perPage: string,
    totalPages: string,
    totalResults: string,
    currentPage: number,
  } | null,
  currentPage: string,
  onChange: Function,
}

const Pagination = (props: Props) => {
  const { pagination, currentPage, onChange } = props
  if (!pagination) return null

  const { page, totalPages } = pagination
  const nullBasedPage = parseInt(page, 10) - 1

  return (
    <ReactPaginate
      previousLabel="Vorherige"
      nextLabel="Weiter"
      pageCount={totalPages}
      pageRangeDisplayed={5}
      marginPagesDisplayed={1}
      initialPage={nullBasedPage}
      containerClassName="pagination-container"
      activeClassName="active"
      onPageChange={onChange}
      forcePage={currentPage - 1}
    />
  )
  // <ReactPaginate
  // breakLabel={'...'}
  // breakClassName={'break-me'}
  // pageCount={this.state.pageCount}
  // marginPagesDisplayed={2}
  // pageRangeDisplayed={5}
  // onPageChange={this.handlePageClick}
  // containerClassName={'pagination'}
  // subContainerClassName={'pages pagination'}
  // activeClassName={'active'}
}

export default Pagination
