// @flow
// import * as React from 'react'
import styled from 'styled-components'

const Label = styled.label`
  display: block;
  margin: 0 0 0.4rem;
  font-size: 0.85rem;
  font-weight: bold;
`

export default Label
