// @flow
import * as Yup from 'yup'
// import moment from 'moment'

const ratingValidationMessage = 'Bitte gibt eine Bewertung ab.'
const rating = Yup.number()
  .moreThan(0, ratingValidationMessage)
  .lessThan(5.0001, ratingValidationMessage)

const validationSchema = Yup.object().shape({
  profTitle: Yup.string().max(50),
  // .required('Bitte gebe eine E-Mail-Adresse an.'),
  profFirstName: Yup.string()
    .min(1)
    .max(100)
    .required('Bitte gib einen Vornamen an.'),
  profLastName: Yup.string()
    .min(1)
    .max(100)
    .required('Bitte gib einen Nachnamen an.'),
  supervisorTitle: Yup.string().max(50),
  supervisorFirstName: Yup.string()
    .min(1)
    .max(100)
    .required('Bitte gib einen Vornamen an.'),
  supervisorLastName: Yup.string()
    .min(1)
    .max(100)
    .required('Bitte gib einen Nachnamen an.'),
  institution: Yup.string().required(
    'Bitte gib eine Institution oder Falkultät an.'
  ),
  beginOfSupervisorship: Yup.date()
    .nullable()
    .when('endOfSupervisorship', {
      is: (v) => v != null,
      then: (s) =>
        s.max(
          Yup.ref('endOfSupervisorship'),
          'Bitte gib ein Datum zeitlich vor dem Ende an.'
        ),
    })
    // .max(new Date(), 'Bitte gib ein gültiges Datum an.')
    .typeError('Bitte gibt ein gültiges Datum an.')
    .required('Bitte gib den Beginn des Betreuungsverhältnisses an.'),
  // .max(moment(Yup.ref('endOfSupervisorship')).subtract('1', 'days'), 'LOL'),
  endOfSupervisorship: Yup.date()
    .nullable()
    .typeError('Bitte gibt ein gültiges Datum an.')
    .required('Bitte gib das Ende des Betreuungsverhältnisses an.')
    .min(
      Yup.ref('beginOfSupervisorship'),
      'Bitte gib ein Datum zeitlich nach dem Beginn an.'
    ),
  doctoralThesisType: Yup.string().required(
    'Bitte gib die Art der Doktorarbeit an.'
  ),
  methods: Yup.string().required('Bitte gib mindestens eine Methode an.'),
  positiveAspects: Yup.string()
    .min(1)
    .max(2000)
    .required('Bitte gib einen Bewertungstext an.'),
  negativeAspects: Yup.string()
    .min(1)
    .max(2000)
    .required('Bitte gib einen Bewertungstext an.'),
  ratingOverall: rating,
  ratingCompetence: rating,
  ratingStructure: rating,
  ratingConsultations: rating,
  ratingCommitment: rating,
  ratingPromotion: rating,
})

export default validationSchema
