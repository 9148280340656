// @flow
import styled from 'styled-components'
import * as v from '../../config/variables'

const InfoMessage = styled.div`
  color: ${v.gray};
  font-size: 0.75rem;
  margin: 0.2rem 0 1rem;
`

export default InfoMessage
