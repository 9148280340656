// @flow
import React from 'react'
import { Link as LinkGeneric } from 'gatsby'
import moment from 'moment'
import { Row, Col } from 'reactstrap'

import styled from 'styled-components'
import { lighten } from 'polished'

import { HeadlineReview as HeadlineReviewGeneric } from '../UI'
import StarRating from '../../app/components/StarRating'
import * as v from '../../config/variables'

type Props = {
  review: Object,
}

const HeadlineReview = styled(HeadlineReviewGeneric)`
  margin: 0 0 0.5rem !important;
`

const Link = styled(LinkGeneric)`
  color: ${v.white};
  padding: 0.5rem 1rem;
  display: block;
`

const StyledReviewListItem = styled.li`
  &:hover {
    > ${Link} {
      background-color: ${lighten(0.55, v.disscheckGreen)};
    }
  }

  > ${Link} {
    color: ${v.textColor};
    background-color: ${v.grayLighter};
    padding: 1rem 1rem 0.6rem;
    border-radius: 5px;
    transition: background-color ease 0.3s;
    cursor: pointer;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
`

// const Date = styled.span`
//   display: block;
//   color: ${v.gray};
//   font-size: 0.9rem;
// `

const ReviewListItem = (props: Props) => {
  const { review } = props
  const {
    id: itemId,
    title,
    date,
    category: { name: institution },
    fields: {
      // field_54: supervisorLastName,
      // field_57: supervisorTitle,
      field_65: overallRating,
      field_58: profTitle,
      field_60: profLastName,
    },
  } = review
  const now = moment(date)
  // const supervisorFullName = supervisorTitle
  //   ? `${supervisorTitle} ${supervisorLastName}`
  //   : supervisorLastName
  const professorFullName = profTitle
    ? `${profTitle} ${profLastName}`
    : profLastName

  // const friendlyTime = now.format('LL')
  // const friendlyTime =
  //   now > moment().subtract(10, 'days') ? now.fromNow() : now.format('LL')

  return (
    <StyledReviewListItem>
      <Link
        to={`/app/rezensionen/${itemId}`}
        title={`${title} – ${now.format('LL')}`}
      >
        <Row>
          <Col>
            <HeadlineReview as="h3">
              {institution}: {professorFullName}
            </HeadlineReview>
          </Col>
        </Row>
        <Row>
          <Col>
            {/* <Date>{friendlyTime}</Date> */}
            <StarRating
              starValue={parseInt(overallRating, 10)}
              size="1rem"
              spacing="0.05rem"
            />
          </Col>
        </Row>
      </Link>
    </StyledReviewListItem>
  )
}

export default ReviewListItem
