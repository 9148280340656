// @flow
// import * as React from 'react'
import styled from 'styled-components'

const FieldSet = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
`

export default FieldSet
