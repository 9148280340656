// @flow
import React from 'react'
import { navigate } from 'gatsby'

// redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ActionCreators from '../../store/actions'

type Props = {
  component: any,
  user: Object,
  location: Object,
}

type State = {}

const loggedIn = (user) => (user || {}).isLoggedIn

class PrivateRoute extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { user, location } = nextProps
    const { pathname } = location

    // First check for user NOT logged in.
    if (!loggedIn(user) && (pathname !== '/login/' || pathname !== '/login')) {
      navigate('/login', { replace: true })
      return false
    }

    return prevState
  }

  render() {
    const { component: Component, user } = this.props
    // const sessionUser = SessionHelper.getSessionUser()

    if (!loggedIn(user)) return null

    return <Component {...this.props} />
  }
}

function mapStateToProps({ user }) {
  return { user }
}

function mapDispatchToProps(dispatch: Function) {
  return {
    dispatch,
    ...bindActionCreators(ActionCreators, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
