// @flow
const categoryData = [
  { name: 'Meine Einrichtung/Fakultät ist nicht aufgeführt', value: '393' },
  {
    type: 'group',
    name: 'Medizinische Fakultät',
    items: [
      { name: 'Institut für Anatomie', value: '322' },
      {
        name: 'Arbeits- und Sozialmedizin',
        value: '323',
      },
      { name: 'Geschichte der Medizin', value: '324' },
      { name: 'Immunologie', value: '325' },
      { name: 'Klinische Genetik', value: '326' },
      { name: 'Klinische Pharmakologie', value: '327' },
      { name: 'Medizinische Informatik und Biometrie', value: '328' },
      { name: 'Medizinische Mikrobiologie und Hygiene', value: '329' },
      { name: 'Pharmakologie und Toxikologie', value: '330' },
      { name: 'Physiologie', value: '331' },
      { name: 'Physiologische Chemie', value: '332' },
      { name: 'Rechtsmedizin', value: '333' },
      { name: 'Virologie', value: '334' },
      { name: 'Medizinische Physik', value: '335' },
    ],
  },
  {
    type: 'group',
    name: 'Universitätsklinikum Dresden',
    items: [
      { name: 'Anästhesiologie und Intensivtherapie', value: '342' },
      { name: 'Augenheilkunde', value: '343' },
      { name: 'Kinderchirurgie', value: '344' },
      { name: 'Neurochirurgie', value: '345' },
      { name: 'Unfall- und Wiederherstellungschirurgie', value: '346' },
      { name: 'Viszeral-, Thorax- und Gefäßchirurgie', value: '347' },
      { name: 'Kardiochirurgie', value: '348' },
      { name: 'Dermatologie', value: '349' },
      { name: 'Frauenheilkunde und Geburtshilfe', value: '350' },
      { name: 'Hals-, Nasen- und Ohrenheilkunde', value: '351' },
      { name: 'Kinder- und Jugendmedizin', value: '352' },
      { name: 'Kinder- und Jugendpsychiatrie', value: '353' },
      { name: 'Neurologie', value: '354' },
      { name: 'Nuklearmedizin', value: '355' },
      { name: 'Orthopädie', value: '356' },
      { name: 'Psychiatrie und Psychotherapie', value: '357' },
      { name: 'Psychotherapie und -somatik', value: '358' },
      { name: 'Strahlentherapie und Radioonkologie', value: '359' },
      { name: 'Urologie', value: '360' },
      { name: 'MK I (Medizinische Klinik und Poliklinik I)', value: '361' },
      { name: 'Kardiologie / Herzzentrum', value: '362' },
      { name: 'MK II (Medizinische Klinik und Poliklinik II)', value: '363' },
      { name: 'MK III (Medizinische Klinik und Poliklinik III)', value: '364' },
      { name: 'Allgemeinmedizin', value: '365' },
      {
        name: 'Medizinische Psychologie und Medizinische Soziologie',
        value: '366',
      },
      { name: 'Radiologische Diagnostik', value: '367' },
      { name: 'Neuroradiologie', value: '368' },
      { name: 'Klinische Chemie und Laboratoriumsmedizin', value: '369' },
      { name: 'Klinische Pathobiochemie', value: '370' },
      { name: 'Pathologie / Rechtsmedizin', value: '371' },
      { name: 'Sonstige (Humanmedizin)', value: '379' },
    ],
  },
  {
    type: 'group',
    name: 'UniversitätsZahnMedizin',
    items: [
      {
        name: 'Mund-, Kiefer- und Gesichtschirurgie',
        value: '372',
      },
      { name: 'Kieferorthopädie', value: '373' },
      { name: 'Kinderzahnheilkunde', value: '374' },
      { name: 'Parodontologie', value: '375' },
      { name: 'Zahnärztliche Prothetik', value: '376' },
      { name: 'Zahnerhaltung', value: '377' },
      { name: 'Sonstige (Zahnmedizin)', value: '378' },
    ],
  },
  {
    type: 'group',
    name: 'UniversitätsZahnMedizin',
    items: [
      {
        name: 'CRTD (Center for Regenerative Therapies Dresden)',
        value: '336',
      },
      { name: 'Biotechnologisches Zentrum Dresden (BIOTEC)', value: '337' },
      {
        name: 'OncoRay (Center for Radiation Research in Oncology)',
        value: '338',
      },
      {
        name:
          'MPI-CBG (Max Planck Institut of Molecular Cell Biology and Genetics)',
        value: '339',
      },
    ],
  },
  {
    type: 'group',
    name: 'Extern der TU Dresden',
    items: [
      { name: 'Externe Promotion (Uni, Forschungszentrum)', value: '340' },
      { name: 'Externe Promotion (Unternehmen)', value: '341' },
    ],
  },
]

export default categoryData
